@import url(https://fonts.googleapis.com/css?family=Rubik:400,600,900);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script:400,600,900);


body {
  font-family: Rubik, Arial, Helvetica, sans-serif;
  /* font-family: Arial, Helvetica, sans-serif; */
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}

.App {
  text-align: center;
  /* width: 100%; */
  /* max-width: 500px; */
}

.main-screen {
  background-color: #f4a026;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: linear-gradient(to top left, #1878BA 0%, #1878BA 50%, white 50%, white 50.5%, #f4a026 50.5%, #f4a026 100%);
}

.triangle-logo {
  transition: all 300ms;
  filter: drop-shadow(5px 5px 5px #222);
}

.triangle-logo:hover {
  transform: scale(1.05);
}

.triangle-logo:active {
  transform: scale(1);
}


#triangle-topleft {
  position: absolute;
  top: 18%;
  left: 18%;
}


#triangle-bottomright {
  position: absolute;
  bottom: 18%;
  right: 18%;
}


header {
  background-color: #f4a026;
  color: black;
  font-size: 1.5rem;
  font-weight: 900;
  height: 100px;
  /* padding: 10px; */
  border-radius: 0 0 1rem 1rem;
  transition: all 300ms linear;
  /* transition: max-height 0.7s linear; */
}

header.full {
  border-radius: 0;
  height: 100vh;
}

.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-nav>div {
  padding: 5px 15px;
  transition: all 300ms;
  border-radius: 10px;
  margin: 10px;
}

.main-nav>div:hover {
  background-color: #dc8400;
}

.main-nav>div {
  padding: 5px 15px;
}


header>nav {
  flex: 1;
}

nav>li {
  list-style: none;
  margin: 10px 30px;
  padding: 10px 0px;
  cursor: default;
  transition: all 300ms;
}

nav>li:hover {
  background-color: #dc8400;
  border-radius: 10px;
  transform: scale(1.05);
}

nav>li:active {
  transform: scale(1.02);
  user-select: none;

}

#showMe {
  animation: cssAnimation 0s 320ms forwards;
  visibility: hidden;
}

@keyframes cssAnimation {
  to {
    visibility: visible;
  }
}

/* .app-logo {
  transition: all 300ms;

}

.app-logo:hover {
  margin: 50px 0px;
} */

.title {
  font-size: 2rem;
  margin: 20px;
  font-weight: 900;
}

.category-heading {
  font-size: 1.5rem;
  color: #f4a026;
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid #f4a026;
  margin: 2rem 0;
}

.menuItem {
  background-color: white;
  color: black;
  margin: 30px 20px;
  padding: 10px;
  border-radius: 1rem;
  border: 1px solid gray;
  text-align: left;
  font-size: 1.5rem;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 300ms;
}

.menuItem:hover {
  transform: scale(1.05);
}

.menuItem:active {
  transform: scale(1);
}

.menuItem-discription {
  margin-top: 5px;
  font-size: 1.2rem;
  font-weight: 400;
}

.signature {
  background-color: #f4a026;
  font-size: 2rem;
}

.signature .menuItem-title {
  font-family: 'Dancing Script', Rubik, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 2.5rem;

}

footer {
  margin: 40px 0px;
}

footer a {
  color: #f4a026;
}